import { SvgIcon, SvgIconProps } from '@mui/material'

export default function FaceDown(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM7.50004 9C7.50004 8.17157 8.17161 7.5 9.00004 7.5C9.82846 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82846 10.5 9.00004 10.5C8.17161 10.5 7.50004 9.82843 7.50004 9ZM13.5 9C13.5 8.17157 14.1716 7.5 15 7.5C15.8285 7.5 16.5 8.17157 16.5 9C16.5 9.82843 15.8285 10.5 15 10.5C14.1716 10.5 13.5 9.82843 13.5 9ZM7.40006 16.7999C7.84049 17.1302 8.46474 17.0423 8.79692 16.6041L8.80156 16.5981C8.80751 16.5906 8.81883 16.5766 8.83537 16.5568C8.86852 16.5173 8.92225 16.4557 8.99548 16.3793C9.14278 16.2256 9.36413 16.0171 9.65073 15.8086C10.2278 15.389 11.0256 14.9999 12.0001 14.9999C12.9745 14.9999 13.7723 15.389 14.3494 15.8086C14.636 16.0171 14.8573 16.2256 15.0046 16.3793C15.0779 16.4557 15.1316 16.5173 15.1647 16.5568C15.1813 16.5766 15.1926 16.5906 15.1986 16.5981L15.2032 16.6041C15.5354 17.0423 16.1596 17.1302 16.6001 16.7999C17.0419 16.4685 17.1314 15.8417 16.8001 15.3999L16.6967 15.2711C16.6381 15.2012 16.5551 15.1066 16.4486 14.9955C16.2365 14.7742 15.9266 14.4827 15.5257 14.1912C14.7278 13.6109 13.5256 12.9999 12.0001 12.9999C10.4745 12.9999 9.27233 13.6109 8.47439 14.1912C8.07349 14.4827 7.76358 14.7742 7.55151 14.9955C7.44506 15.1066 7.36207 15.2012 7.30342 15.2711C7.26801 15.3133 7.23377 15.3564 7.20006 15.3999C6.86869 15.8417 6.95823 16.4685 7.40006 16.7999Z'
          fill='currentColor'
        />
      </svg>
    </SvgIcon>
  )
}
