import { createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { graphqlBaseURL } from 'config/config'
import { getOrRefreshAccessTokenWithCognitoOrStoredCredentials } from 'utils/credentials_service'

const httpLink = createHttpLink({
  uri: graphqlBaseURL
})

const authLink = setContext(async (_, { headers }) => {
  let token
  try {
    token = await getOrRefreshAccessTokenWithCognitoOrStoredCredentials()
  } catch (e) {
    console.error('Failed to retrieve token for API request', e)
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export default authLink.concat(httpLink)
