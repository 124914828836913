import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: TypographyStyleOptions
    subtitle4: TypographyStyleOptions
    footnote: TypographyStyleOptions
  }

  interface TypographyVariantsOptions {
    subtitle3?: TypographyStyleOptions
    subtitle4?: TypographyStyleOptions
    footnote?: TypographyStyleOptions
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true
    subtitle4: true
    footnote: true
  }
}

export const typography: TypographyOptions = {
  fontFamily: [
    'Work Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    "'Segoe UI'",
    "'Roboto'",
    "'Oxygen'",
    "'Ubuntu'",
    "'Cantarell'",
    "'Fira Sans'",
    "'Droid Sans'",
    "'Helvetica Neue'",
    'sans-serif'
  ].join(','),
  h1: {
    fontSize: 128,
    lineHeight: 1.09,
    letterSpacing: -2
  },
  h2: {
    fontSize: 96,
    lineHeight: 1.16,
    letterSpacing: -2
  },
  h3: {
    fontSize: 80,
    lineHeight: 1.15,
    letterSpacing: -2
  },
  h4: {
    fontSize: 64,
    lineHeight: 1.125,
    letterSpacing: -1.5
  },
  h5: {
    fontSize: 48,
    lineHeight: 1.16,
    letterSpacing: -1
  },
  h6: {
    fontSize: 40,
    lineHeight: 1.2,
    letterSpacing: -0.8
  },
  subtitle1: {
    fontSize: 32,
    lineHeight: 1.25,
    letterSpacing: -0.8
  },
  subtitle2: {
    fontSize: 24,
    lineHeight: 1.16,
    letterSpacing: -0.6
  },
  subtitle3: {
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: -0.4
  },
  subtitle4: {
    fontSize: 18,
    lineHeight: 1.16,
    letterSpacing: -0.4
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: -0.4
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.42,
    letterSpacing: -0.4
  },
  footnote: {
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: -0.2
  },
  fontWeightBold: '600 !important'
}
