/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An RFC3339 encoded UTC date string. */
  DateTime: { input: any; output: any }
  Nothing: { input: any; output: any }
  _Any: { input: any; output: any }
  _FieldSet: { input: any; output: any }
}

export enum AccountFlagType {
  MailroomVersionsSwitchAllowed = 'MAILROOM_VERSIONS_SWITCH_ALLOWED',
  NewMailroomEnabled = 'NEW_MAILROOM_ENABLED'
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED',
  Unspecified = 'UNSPECIFIED'
}

export enum AccountType {
  Business = 'BUSINESS',
  Personal = 'PERSONAL',
  Unspecified = 'UNSPECIFIED'
}

export type AddItemsToShippingCartInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export enum ApplicantStatusFilter {
  NonVerifiedOnly = 'NON_VERIFIED_ONLY',
  Unspecified = 'UNSPECIFIED',
  VerifiedOnly = 'VERIFIED_ONLY'
}

export type ApplicantsByInboxIdInput = {
  inboxId: Scalars['ID']['input']
  statusFilter?: InputMaybe<ApplicantStatusFilter>
}

export type AuthorizeShippingAddressInput = {
  inboxId: Scalars['ID']['input']
  shippingAddressId: Scalars['ID']['input']
  signature: Scalars['String']['input']
}

export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
  Unspecified = 'UNSPECIFIED'
}

export enum BankingInstitutionDeliveryType {
  Expedited = 'EXPEDITED',
  Standard = 'STANDARD',
  Unspecified = 'UNSPECIFIED'
}

export type CancelMailItemScanInput = {
  mailItemID: Scalars['ID']['input']
}

export type CancelMailItemShredInput = {
  mailItemID: Scalars['ID']['input']
}

export type CancelMailItemsCheckDepositInput = {
  deposits: Array<MailItemCheckDeposit>
  inboxId: Scalars['ID']['input']
}

export type CancelMailItemsScanInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type CancelMailItemsShredInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type CancelShipmentInput = {
  inboxId: Scalars['ID']['input']
  shipmentId: Scalars['ID']['input']
}

export enum CheckDepositPlanType {
  Oneoff = 'ONEOFF',
  Subscription = 'SUBSCRIPTION',
  Unspecified = 'UNSPECIFIED'
}

export type ClearShippingCartInput = {
  inboxId: Scalars['ID']['input']
}

export enum ComplianceStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type CreateCheckDepositBankAccountInput = {
  accountNumber: Scalars['String']['input']
  accountType: BankAccountType
  contactAddress: Scalars['String']['input']
  contactBusinessName?: InputMaybe<Scalars['String']['input']>
  contactName: Scalars['String']['input']
  contactPhone: Scalars['String']['input']
  displayName: Scalars['String']['input']
  inboxId: Scalars['ID']['input']
  routingNumber: Scalars['String']['input']
}

export type CreateGeneralShareLinkInput = {
  mailItemId: Scalars['ID']['input']
}

export type CreateMailItemIntegrationJobsInput = {
  connectionId: Scalars['ID']['input']
  entitySource: Scalars['String']['input']
  entityType: Scalars['String']['input']
  inboxId: Scalars['ID']['input']
  jobConfig?: InputMaybe<MailItemIntegrationCreateJobConfig>
  jobType: IntegrationJobType
  mailItemIds: Array<Scalars['ID']['input']>
}

export type DeleteShareLinkInput = {
  linkKey: Scalars['String']['input']
  mailItemId: Scalars['ID']['input']
}

export enum EventSource {
  EcmMobileapp = 'ECM_MOBILEAPP',
  EcmWebappDesktop = 'ECM_WEBAPP_DESKTOP',
  EcmWebappMobile = 'ECM_WEBAPP_MOBILE'
}

export type GetCurrentShippingCartInput = {
  inboxId: Scalars['ID']['input']
}

export type GetShipmentByItemIdInput = {
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}

export type GetShipmentsByIdsInput = {
  shipmentIds: Array<Scalars['ID']['input']>
}

export enum IntegrationJobType {
  RecordPayment = 'RECORD_PAYMENT',
  Unspecified = 'UNSPECIFIED',
  UploadBill = 'UPLOAD_BILL',
  UploadPiece = 'UPLOAD_PIECE'
}

export enum MailItemActionType {
  CancelDepositCheck = 'CANCEL_DEPOSIT_CHECK',
  CancelScan = 'CANCEL_SCAN',
  CancelShip = 'CANCEL_SHIP',
  CancelShred = 'CANCEL_SHRED',
  ChangeSenderName = 'CHANGE_SENDER_NAME',
  CopyLinkToPdf = 'COPY_LINK_TO_PDF',
  DepositCheck = 'DEPOSIT_CHECK',
  DownloadPdf = 'DOWNLOAD_PDF',
  MarkNotViewed = 'MARK_NOT_VIEWED',
  MarkViewed = 'MARK_VIEWED',
  MoveToArchive = 'MOVE_TO_ARCHIVE',
  MoveToInbox = 'MOVE_TO_INBOX',
  MoveToTrash = 'MOVE_TO_TRASH',
  NotMyMail = 'NOT_MY_MAIL',
  Scan = 'SCAN',
  SetCheckDetected = 'SET_CHECK_DETECTED',
  ShareItemViaIntegration = 'SHARE_ITEM_VIA_INTEGRATION',
  SharePdf = 'SHARE_PDF',
  Ship = 'SHIP',
  Shred = 'SHRED',
  Unspecified = 'UNSPECIFIED'
}

export enum MailItemActionUnavailabilityReason {
  IdentityNotVerified = 'IDENTITY_NOT_VERIFIED',
  ItemStateArchived = 'ITEM_STATE_ARCHIVED',
  ItemStateBlockedDueToAccountRestrictions = 'ITEM_STATE_BLOCKED_DUE_TO_ACCOUNT_RESTRICTIONS',
  ItemStateInInbox = 'ITEM_STATE_IN_INBOX',
  ItemStateNoChecksDetected = 'ITEM_STATE_NO_CHECKS_DETECTED',
  ItemStateRecycled = 'ITEM_STATE_RECYCLED',
  ItemStateShipped = 'ITEM_STATE_SHIPPED',
  ItemStateShredded = 'ITEM_STATE_SHREDDED',
  ItemStateVirtual = 'ITEM_STATE_VIRTUAL',
  OperationCancelLocked = 'OPERATION_CANCEL_LOCKED',
  OperationCompleted = 'OPERATION_COMPLETED',
  OperationNotRequested = 'OPERATION_NOT_REQUESTED',
  OperationPendingDepositCheck = 'OPERATION_PENDING_DEPOSIT_CHECK',
  OperationPendingScan = 'OPERATION_PENDING_SCAN',
  OperationPendingShip = 'OPERATION_PENDING_SHIP',
  OperationPendingShred = 'OPERATION_PENDING_SHRED',
  Other = 'OTHER',
  Unspecified = 'UNSPECIFIED'
}

export type MailItemCheckDeposit = {
  checkDepositId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}

export enum MailItemCheckStatus {
  CheckDetected = 'CHECK_DETECTED',
  DepositCompleted = 'DEPOSIT_COMPLETED',
  DepositError = 'DEPOSIT_ERROR',
  DepositPending = 'DEPOSIT_PENDING',
  DepositRequested = 'DEPOSIT_REQUESTED',
  NoCheck = 'NO_CHECK',
  Unspecified = 'UNSPECIFIED'
}

export enum MailItemDigitalStatus {
  ActionsBlockedNoReverification = 'ACTIONS_BLOCKED_NO_REVERIFICATION',
  NoScan = 'NO_SCAN',
  ScanCompleted = 'SCAN_COMPLETED',
  ScanInProgress = 'SCAN_IN_PROGRESS',
  ScanRequested = 'SCAN_REQUESTED',
  Unspecified = 'UNSPECIFIED'
}

export type MailItemFilters = {
  checkStatusIn?: InputMaybe<Array<MailItemCheckStatus>>
  contains?: InputMaybe<Scalars['String']['input']>
  digitalStatusIn?: InputMaybe<Array<MailItemDigitalStatus>>
  flag?: InputMaybe<Array<MailItemFlag>>
  folder?: InputMaybe<MailItemFolder>
  inShippingCart?: InputMaybe<Scalars['Boolean']['input']>
  physicalStatusIn?: InputMaybe<Array<MailItemPhysicalStatus>>
  recipientId?: InputMaybe<Scalars['ID']['input']>
  unassignedRecipientOnly?: InputMaybe<Scalars['Boolean']['input']>
  viewed?: InputMaybe<Scalars['Boolean']['input']>
}

export enum MailItemFlag {
  CheckDetected = 'CHECK_DETECTED',
  MediaDetected = 'MEDIA_DETECTED',
  OutstandingStorageFee = 'OUTSTANDING_STORAGE_FEE',
  Unspecified = 'UNSPECIFIED'
}

export enum MailItemFolder {
  All = 'ALL',
  Archive = 'ARCHIVE',
  Inbox = 'INBOX',
  Trash = 'TRASH',
  Unspecified = 'UNSPECIFIED'
}

export type MailItemIntegrationCreateJobConfig = {
  accountId: Scalars['ID']['input']
  checkId: Scalars['ID']['input']
  customerId: Scalars['ID']['input']
  invoiceId: Scalars['ID']['input']
}

export enum MailItemPhysicalStatus {
  InStorage = 'IN_STORAGE',
  ShipCompleted = 'SHIP_COMPLETED',
  ShipInProgress = 'SHIP_IN_PROGRESS',
  ShipRequested = 'SHIP_REQUESTED',
  ShredCompleted = 'SHRED_COMPLETED',
  ShredInProgress = 'SHRED_IN_PROGRESS',
  ShredRequested = 'SHRED_REQUESTED',
  StorageFee = 'STORAGE_FEE',
  Unspecified = 'UNSPECIFIED',
  VirtualMail = 'VIRTUAL_MAIL'
}

export enum MailItemPostScanAction {
  Shred = 'SHRED',
  Store = 'STORE',
  Unspecified = 'UNSPECIFIED'
}

export enum MailItemPostShredAction {
  MoveToArchive = 'MOVE_TO_ARCHIVE',
  MoveToTrash = 'MOVE_TO_TRASH',
  Unspecified = 'UNSPECIFIED'
}

export enum MailItemScanType {
  Bitonal = 'BITONAL',
  Color = 'COLOR',
  Unspecified = 'UNSPECIFIED'
}

export type MarkMailItemViewedInput = {
  mailItemID: Scalars['ID']['input']
}

export type MarkMailItemsNotViewedInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type MarkMailItemsViewedInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type MarkNotMyMailsInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type MoveMailItemsInput = {
  folder: MailItemFolder
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export enum PostDepositOperation {
  Shred = 'SHRED',
  Store = 'STORE',
  Unspecified = 'UNSPECIFIED'
}

export type RemoveItemsFromShippingCartInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export type RequestMailItemScanInput = {
  mailItemID: Scalars['ID']['input']
  postScanAction: VirtualMailItemPostScanAction
  scanType: VirtualMailItemScanType
}

export type RequestMailItemShredInput = {
  mailItemID: Scalars['ID']['input']
  postShredAction?: InputMaybe<VirtualMailItemPostShredAction>
}

export type RequestMailItemsCheckDepositInput = {
  bankAccountId: Scalars['ID']['input']
  checkDepositPlanTypeOption: CheckDepositPlanType
  inboxId: Scalars['ID']['input']
  isExpedited: Scalars['Boolean']['input']
  mailItemIds: Array<Scalars['ID']['input']>
  postDepositOperation: PostDepositOperation
}

export type RequestMailItemsScanInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
  postScanAction: MailItemPostScanAction
  scanType: MailItemScanType
}

export type RequestMailItemsShredInput = {
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
  postShredAction?: InputMaybe<MailItemPostShredAction>
}

export type RequestRatesInput = {
  inboxId: Scalars['ID']['input']
  isForImmediateShipping: Scalars['Boolean']['input']
  shipmentPackages: Array<ShipmentPackage>
  shippingAddressId: Scalars['ID']['input']
}

export type RequestShipmentInput = {
  inboxId: Scalars['ID']['input']
  shippingRateId: Scalars['ID']['input']
}

export enum Resource {
  Account = 'ACCOUNT',
  Inbox = 'INBOX',
  Service = 'SERVICE'
}

export type ReverificationDeadlineModalDisplayedInput = {
  accountId: Scalars['ID']['input']
  source: EventSource
}

export enum ReverificationReason {
  DocumentExpired = 'DOCUMENT_EXPIRED',
  InvalidData = 'INVALID_DATA'
}

export type SetMailItemCheckDetectedInput = {
  inboxId: Scalars['ID']['input']
  isDetected: Scalars['Boolean']['input']
  mailItemId: Scalars['ID']['input']
}

export type SetNewMailroomEnabledInput = {
  accountId: Scalars['ID']['input']
  feedbackMessage?: InputMaybe<Scalars['String']['input']>
  userAgent?: InputMaybe<Scalars['String']['input']>
  value: Scalars['Boolean']['input']
}

export type ShareMailItemsByEmailInput = {
  emails: Array<Scalars['String']['input']>
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']>
}

export enum ShipmentContentsType {
  Documents = 'Documents',
  Merchandise = 'Merchandise',
  ReturnedGoods = 'ReturnedGoods'
}

export type ShipmentCustomsItem = {
  declaredValueInCents: Scalars['Int']['input']
  description: Scalars['String']['input']
  hsTariffCode: Scalars['String']['input']
}

export type ShipmentPackage = {
  aesItn?: InputMaybe<Scalars['String']['input']>
  contentsType?: InputMaybe<ShipmentContentsType>
  customsItems?: InputMaybe<Array<ShipmentCustomsItem>>
  declaredValueInCents: Scalars['Int']['input']
  isInsured: Scalars['Boolean']['input']
  mailItemIds: Array<Scalars['ID']['input']>
  shippingInstructions: Scalars['String']['input']
  signatureOnDelivery: Scalars['Boolean']['input']
}

export type ShippingAddressInput = {
  addressee: Scalars['String']['input']
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  countryCode: Scalars['String']['input']
  inboxId: Scalars['ID']['input']
  line1: Scalars['String']['input']
  line2?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
  postalCode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export enum Source {
  InputField = 'INPUT_FIELD',
  Parameter = 'PARAMETER'
}

export type ToggleShareLinkAccessInput = {
  linkKey: Scalars['String']['input']
  mailItemId: Scalars['ID']['input']
}

export type UpdateMailItemInput = {
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
  senderName: Scalars['String']['input']
}

export type ValidateBankRoutingNumberInput = {
  routingNumber: Scalars['String']['input']
}

export enum VirtualMailFeatureFlag {
  NewSsvFlow = 'NEW_SSV_FLOW',
  PredefinedDocumentPairsFlow = 'PREDEFINED_DOCUMENT_PAIRS_FLOW',
  PrefilledGetStarted = 'PREFILLED_GET_STARTED',
  SmartCameraMode = 'SMART_CAMERA_MODE',
  Unspecified = 'UNSPECIFIED'
}

export enum VirtualMailItemDigitalStatus {
  VirtualMailItemDigitalStatusActionsBlockedNoReverification = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_ACTIONS_BLOCKED_NO_REVERIFICATION',
  VirtualMailItemDigitalStatusNoScan = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_NO_SCAN',
  VirtualMailItemDigitalStatusScanCompleted = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_SCAN_COMPLETED',
  VirtualMailItemDigitalStatusScanInProgress = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_SCAN_IN_PROGRESS',
  VirtualMailItemDigitalStatusScanRequested = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_SCAN_REQUESTED',
  VirtualMailItemDigitalStatusUnspecified = 'VIRTUAL_MAIL_ITEM_DIGITAL_STATUS_UNSPECIFIED'
}

export enum VirtualMailItemPhysicalStatus {
  VirtualMailItemPhysicalStatusInStorage = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_IN_STORAGE',
  VirtualMailItemPhysicalStatusShipCompleted = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHIP_COMPLETED',
  VirtualMailItemPhysicalStatusShipInProgress = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHIP_IN_PROGRESS',
  VirtualMailItemPhysicalStatusShipRequested = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHIP_REQUESTED',
  VirtualMailItemPhysicalStatusShredCompleted = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHRED_COMPLETED',
  VirtualMailItemPhysicalStatusShredInProgress = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHRED_IN_PROGRESS',
  VirtualMailItemPhysicalStatusShredRequested = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_SHRED_REQUESTED',
  VirtualMailItemPhysicalStatusStorageFee = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_STORAGE_FEE',
  VirtualMailItemPhysicalStatusUnspecified = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_UNSPECIFIED',
  VirtualMailItemPhysicalStatusVirtualMail = 'VIRTUAL_MAIL_ITEM_PHYSICAL_STATUS_VIRTUAL_MAIL'
}

export enum VirtualMailItemPostScanAction {
  VirtualMailItemPostScanActionShred = 'VIRTUAL_MAIL_ITEM_POST_SCAN_ACTION_SHRED',
  VirtualMailItemPostScanActionStore = 'VIRTUAL_MAIL_ITEM_POST_SCAN_ACTION_STORE',
  VirtualMailItemPostScanActionUnspecified = 'VIRTUAL_MAIL_ITEM_POST_SCAN_ACTION_UNSPECIFIED'
}

export enum VirtualMailItemPostShredAction {
  VirtualMailItemPostShredActionMoveToArchive = 'VIRTUAL_MAIL_ITEM_POST_SHRED_ACTION_MOVE_TO_ARCHIVE',
  VirtualMailItemPostShredActionMoveToTrash = 'VIRTUAL_MAIL_ITEM_POST_SHRED_ACTION_MOVE_TO_TRASH',
  VirtualMailItemPostShredActionUnspecified = 'VIRTUAL_MAIL_ITEM_POST_SHRED_ACTION_UNSPECIFIED'
}

export enum VirtualMailItemScanType {
  VirtualMailItemScanTypeBitonal = 'VIRTUAL_MAIL_ITEM_SCAN_TYPE_BITONAL',
  VirtualMailItemScanTypeColor = 'VIRTUAL_MAIL_ITEM_SCAN_TYPE_COLOR',
  VirtualMailItemScanTypeUnspecified = 'VIRTUAL_MAIL_ITEM_SCAN_TYPE_UNSPECIFIED'
}

export enum VirtualMailStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Suspended = 'SUSPENDED',
  Uncommitted = 'UNCOMMITTED',
  Unspecified = 'UNSPECIFIED'
}

export type MailItemDepositDataQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}>

export type MailItemDepositDataQuery = {
  __typename?: 'Query'
  mailItemById: {
    __typename?: 'MailItem'
    deposit?: { __typename?: 'MailItemDeposit'; depositRequestId: string } | null
  }
}

export type CancelMailItemsCheckDepositMutationVariables = Exact<{
  input: CancelMailItemsCheckDepositInput
}>

export type CancelMailItemsCheckDepositMutation = {
  __typename?: 'Mutation'
  cancelMailItemsCheckDeposit: Array<
    | { __typename?: 'ErrorResult' }
    | {
        __typename?: 'MailItem'
        id: string
        checkStatus: MailItemCheckStatus
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type CancelMailItemsScanMutationVariables = Exact<{
  input: CancelMailItemsScanInput
}>

export type CancelMailItemsScanMutation = {
  __typename?: 'Mutation'
  cancelMailItemsScan: Array<{
    __typename?: 'MailItem'
    id: string
    digitalStatus: MailItemDigitalStatus
    itemActions: Array<
      | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
      | {
          __typename?: 'MailItemUnavailableAction'
          type: MailItemActionType
          unavailabilityReason: MailItemActionUnavailabilityReason
        }
    >
  }>
}

export type GetShipmentByItemIdQueryVariables = Exact<{
  input: GetShipmentByItemIdInput
}>

export type GetShipmentByItemIdQuery = {
  __typename?: 'Query'
  getShipmentByItemId?: { __typename?: 'Shipment'; id: string; itemCount: number } | null
}

export type CancelShipmentMutationVariables = Exact<{
  input: CancelShipmentInput
}>

export type CancelShipmentMutation = {
  __typename?: 'Mutation'
  cancelShipment: {
    __typename?: 'CancelShipmentResponse'
    items: Array<{
      __typename?: 'MailItem'
      id: string
      physicalStatus: MailItemPhysicalStatus
      itemActions: Array<
        | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
        | {
            __typename?: 'MailItemUnavailableAction'
            type: MailItemActionType
            unavailabilityReason: MailItemActionUnavailabilityReason
          }
      >
    }>
  }
}

export type CancelItemsShredMutationVariables = Exact<{
  input: CancelMailItemsShredInput
}>

export type CancelItemsShredMutation = {
  __typename?: 'Mutation'
  cancelMailItemsShred: Array<{
    __typename?: 'MailItem'
    id: string
    physicalStatus: MailItemPhysicalStatus
    itemActions: Array<
      | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
      | {
          __typename?: 'MailItemUnavailableAction'
          type: MailItemActionType
          unavailabilityReason: MailItemActionUnavailabilityReason
        }
    >
  }>
}

export type UpdateMailItemMutationVariables = Exact<{
  input: UpdateMailItemInput
}>

export type UpdateMailItemMutation = {
  __typename?: 'Mutation'
  updateMailItem: { __typename?: 'MailItem'; id: string; sender?: string | null }
}

export type RequestMailItemsCheckDepositMutationVariables = Exact<{
  input: RequestMailItemsCheckDepositInput
}>

export type RequestMailItemsCheckDepositMutation = {
  __typename?: 'Mutation'
  requestMailItemsCheckDeposit: Array<
    | { __typename?: 'ErrorResult' }
    | {
        __typename?: 'MailItem'
        id: string
        checkStatus: MailItemCheckStatus
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type CheckDepositDataQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
}>

export type CheckDepositDataQuery = {
  __typename?: 'Query'
  checkDepositBankAccounts: {
    __typename?: 'CheckDepositBankAccountConnection'
    hasNextPage: boolean
    totalCount: number
    edges: Array<{
      __typename?: 'CheckDepositBankAccountEdge'
      cursor: string
      node: {
        __typename?: 'CheckDepositBankAccount'
        id: string
        displayName: string
        bankName: string
        accountType: BankAccountType
        isExpedited: boolean
        routingNumber: string
        accountNumberMasked: string
      }
    }>
  }
  getCheckDepositBillingAddon: {
    __typename?: 'CheckDepositBillingAddon'
    hasCheckDepositAddon: boolean
    checkDepositAddonOptions: Array<{
      __typename?: 'CheckDepositAddonOption'
      name: string
      planType: CheckDepositPlanType
      priceInCents: number
      pricePerCheckInCents: number
      freeChecksPerMonth: number
    }>
  }
}

export type BankAccountEdgeFragment = {
  __typename?: 'CheckDepositBankAccountEdge'
  cursor: string
  node: {
    __typename?: 'CheckDepositBankAccount'
    id: string
    displayName: string
    bankName: string
    accountType: BankAccountType
    isExpedited: boolean
    routingNumber: string
    accountNumberMasked: string
  }
}

export type BankAccountFragment = {
  __typename?: 'CheckDepositBankAccount'
  id: string
  displayName: string
  bankName: string
  accountType: BankAccountType
  isExpedited: boolean
  routingNumber: string
  accountNumberMasked: string
}

export type CheckDepositBillingAddonFragment = {
  __typename?: 'CheckDepositBillingAddon'
  hasCheckDepositAddon: boolean
  checkDepositAddonOptions: Array<{
    __typename?: 'CheckDepositAddonOption'
    name: string
    planType: CheckDepositPlanType
    priceInCents: number
    pricePerCheckInCents: number
    freeChecksPerMonth: number
  }>
}

export type CheckDepositBillingAddonOptionFragment = {
  __typename?: 'CheckDepositAddonOption'
  name: string
  planType: CheckDepositPlanType
  priceInCents: number
  pricePerCheckInCents: number
  freeChecksPerMonth: number
}

export type ValidateBankRoutingNumberMutationVariables = Exact<{
  input: ValidateBankRoutingNumberInput
}>

export type ValidateBankRoutingNumberMutation = {
  __typename?: 'Mutation'
  validateBankRoutingNumber: { __typename?: 'BankRoutingNumberValidation'; isValid: boolean; bankName?: string | null }
}

export type CreateCheckDepositBankAccountMutationVariables = Exact<{
  input: CreateCheckDepositBankAccountInput
}>

export type CreateCheckDepositBankAccountMutation = {
  __typename?: 'Mutation'
  createCheckDepositBankAccount: {
    __typename?: 'CheckDepositBankAccount'
    id: string
    displayName: string
    bankName: string
    accountType: BankAccountType
    isExpedited: boolean
    routingNumber: string
    accountNumberMasked: string
  }
}

export type MarkMailItemsNotViewedMutationVariables = Exact<{
  input: MarkMailItemsNotViewedInput
}>

export type MarkMailItemsNotViewedMutation = {
  __typename?: 'Mutation'
  markMailItemsNotViewed: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItem'
        id: string
        isViewed: boolean
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type MarkMailItemsViewedMutationVariables = Exact<{
  input: MarkMailItemsViewedInput
}>

export type MarkMailItemsViewedMutation = {
  __typename?: 'Mutation'
  markMailItemsViewed: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItem'
        id: string
        isViewed: boolean
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type MoveMailItemsMutationVariables = Exact<{
  input: MoveMailItemsInput
}>

export type MoveMailItemsMutation = {
  __typename?: 'Mutation'
  moveMailItems: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItem'
        id: string
        folder: MailItemFolder
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type MarkNotMyMailsMutationVariables = Exact<{
  input: MarkNotMyMailsInput
}>

export type MarkNotMyMailsMutation = {
  __typename?: 'Mutation'
  markNotMyMails: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItem'
        id: string
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type RequestItemsScanMutationVariables = Exact<{
  input: RequestMailItemsScanInput
}>

export type RequestItemsScanMutation = {
  __typename?: 'Mutation'
  requestMailItemsScan: Array<{
    __typename?: 'MailItem'
    id: string
    digitalStatus: MailItemDigitalStatus
    itemActions: Array<
      | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
      | {
          __typename?: 'MailItemUnavailableAction'
          type: MailItemActionType
          unavailabilityReason: MailItemActionUnavailabilityReason
        }
    >
  }>
}

export type SetMailItemCheckDetectedMutationVariables = Exact<{
  input: SetMailItemCheckDetectedInput
}>

export type SetMailItemCheckDetectedMutation = {
  __typename?: 'Mutation'
  setMailItemCheckDetected: {
    __typename?: 'MailItem'
    id: string
    checkStatus: MailItemCheckStatus
    flags?: Array<MailItemFlag> | null
    itemActions: Array<
      | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
      | {
          __typename?: 'MailItemUnavailableAction'
          type: MailItemActionType
          unavailabilityReason: MailItemActionUnavailabilityReason
        }
    >
  }
}

export type CreateGeneralShareLinkMutationVariables = Exact<{
  input: CreateGeneralShareLinkInput
}>

export type CreateGeneralShareLinkMutation = {
  __typename?: 'Mutation'
  createGeneralShareLink: {
    __typename?: 'MailItemShareData'
    mailItemId: string
    generalLinkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
  }
}

export type CreateMailItemIntegrationJobsMutationVariables = Exact<{
  input: CreateMailItemIntegrationJobsInput
}>

export type CreateMailItemIntegrationJobsMutation = {
  __typename?: 'Mutation'
  createMailItemIntegrationJobs: Array<
    | { __typename?: 'CreateMailItemIntegrationJob'; id: string }
    | { __typename?: 'ErrorResult'; mailItemId: string; errorMessage: string }
  >
}

export type MailItemShareEmailsQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
}>

export type MailItemShareEmailsQuery = { __typename?: 'Query'; mailItemShareEmails: Array<string> }

export type ShareMailItemsByEmailMutationVariables = Exact<{
  input: ShareMailItemsByEmailInput
}>

export type ShareMailItemsByEmailMutation = {
  __typename?: 'Mutation'
  shareMailItemsByEmail: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItemShareData'
        mailItemId: string
        emailShares: Array<{
          __typename?: 'EmailShareData'
          email: string
          linkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
        }>
      }
  >
}

export type DeleteShareLinkMutationVariables = Exact<{
  input: DeleteShareLinkInput
}>

export type DeleteShareLinkMutation = {
  __typename?: 'Mutation'
  deleteShareLink: {
    __typename?: 'MailItemShareData'
    mailItemId: string
    emailShares: Array<{
      __typename?: 'EmailShareData'
      email: string
      linkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
    }>
  }
}

export type MailItemsShareDataQueryVariables = Exact<{
  mailItemIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MailItemsShareDataQuery = {
  __typename?: 'Query'
  mailItemsShareData: Array<{
    __typename?: 'MailItemShareData'
    mailItemId: string
    generalLinkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
    emailShares: Array<{
      __typename?: 'EmailShareData'
      email: string
      linkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
    }>
  }>
}

export type ToggleShareLinkAccessMutationVariables = Exact<{
  input: ToggleShareLinkAccessInput
}>

export type ToggleShareLinkAccessMutation = {
  __typename?: 'Mutation'
  toggleShareLinkAccess: {
    __typename?: 'MailItemShareData'
    mailItemId: string
    generalLinkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
    emailShares: Array<{
      __typename?: 'EmailShareData'
      email: string
      linkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
    }>
  }
}

export type MailItemShareDataFragment = {
  __typename?: 'MailItemShareData'
  mailItemId: string
  generalLinkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
  emailShares: Array<{
    __typename?: 'EmailShareData'
    email: string
    linkData?: { __typename?: 'ShareLinkData'; linkKey: string; isEnabled: boolean } | null
  }>
}

export type AddItemsToShippingCartMutationVariables = Exact<{
  input: AddItemsToShippingCartInput
}>

export type AddItemsToShippingCartMutation = {
  __typename?: 'Mutation'
  addItemsToShippingCart: {
    __typename?: 'ShippingCart'
    inboxId: string
    userId: string
    itemCount: number
    items: Array<{
      __typename?: 'MailItem'
      id: string
      inShippingCart: boolean
      itemActions: Array<
        | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
        | {
            __typename?: 'MailItemUnavailableAction'
            type: MailItemActionType
            unavailabilityReason: MailItemActionUnavailabilityReason
          }
      >
    }>
  }
}

export type ShredMailItemsMutationVariables = Exact<{
  input: RequestMailItemsShredInput
}>

export type ShredMailItemsMutation = {
  __typename?: 'Mutation'
  requestMailItemsShred: Array<
    | { __typename?: 'ErrorResult'; mailItemId: string }
    | {
        __typename?: 'MailItem'
        id: string
        physicalStatus: MailItemPhysicalStatus
        flags?: Array<MailItemFlag> | null
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
  >
}

export type IntegrationProvidersQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
}>

export type IntegrationProvidersQuery = {
  __typename?: 'Query'
  integrationProviders: Array<{
    __typename?: 'IntegrationProvider'
    id: string
    providerName: string
    displayName: string
    authType: string
    authorizationUrl: string
    logoUrl: string
    supportedJobType: IntegrationJobType
    connections: Array<{
      __typename?: 'IntegrationProviderConnection'
      id: string
      connectionName: string
      userEmail: string
      createdAt: any
    }>
  }>
}

export type GetMailItemsQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['ID']['input']>
  filters?: InputMaybe<MailItemFilters>
}>

export type GetMailItemsQuery = {
  __typename?: 'Query'
  mailItems: {
    __typename?: 'MailItemsConnection'
    edges: Array<{
      __typename?: 'MailItemEdge'
      node: {
        __typename?: 'MailItem'
        id: string
        barcode: string
        isViewed: boolean
        sender?: string | null
        receivedAt: any
        physicalStatus: MailItemPhysicalStatus
        digitalStatus: MailItemDigitalStatus
        checkStatus: MailItemCheckStatus
        senderCarrier?: string | null
        folder: MailItemFolder
        mailType: string
        mailSubtype?: string | null
        facility: string
        inShippingCart: boolean
        flags?: Array<MailItemFlag> | null
        recipient?: { __typename?: 'Recipient'; name: string } | null
        enclosure: { __typename?: 'MailItemEnclosure'; smallUrl?: string | null; largeUrl?: string | null }
        scan?: { __typename?: 'MailItemScan'; pdfUrl: string } | null
        physicalDimensions: {
          __typename?: 'MailItemPhysicalDimensions'
          weightInOunces: number
          pageCountEstimate: number
          pageCountActual?: number | null
        }
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  }
}

export type IntegrationProviderFragment = {
  __typename?: 'IntegrationProvider'
  id: string
  providerName: string
  displayName: string
  authType: string
  authorizationUrl: string
  logoUrl: string
  supportedJobType: IntegrationJobType
  connections: Array<{
    __typename?: 'IntegrationProviderConnection'
    id: string
    connectionName: string
    userEmail: string
    createdAt: any
  }>
}

export type MailItemFragment = {
  __typename?: 'MailItem'
  id: string
  barcode: string
  isViewed: boolean
  sender?: string | null
  receivedAt: any
  physicalStatus: MailItemPhysicalStatus
  digitalStatus: MailItemDigitalStatus
  checkStatus: MailItemCheckStatus
  senderCarrier?: string | null
  folder: MailItemFolder
  mailType: string
  mailSubtype?: string | null
  facility: string
  inShippingCart: boolean
  flags?: Array<MailItemFlag> | null
  recipient?: { __typename?: 'Recipient'; name: string } | null
  enclosure: { __typename?: 'MailItemEnclosure'; smallUrl?: string | null; largeUrl?: string | null }
  scan?: { __typename?: 'MailItemScan'; pdfUrl: string } | null
  physicalDimensions: {
    __typename?: 'MailItemPhysicalDimensions'
    weightInOunces: number
    pageCountEstimate: number
    pageCountActual?: number | null
  }
  itemActions: Array<
    | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
    | {
        __typename?: 'MailItemUnavailableAction'
        type: MailItemActionType
        unavailabilityReason: MailItemActionUnavailabilityReason
      }
  >
}

export type GetInboxQueryVariables = Exact<{
  inboxId: Scalars['Int']['input']
}>

export type GetInboxQuery = {
  __typename?: 'Query'
  getInbox: {
    __typename?: 'Inbox'
    id: number
    account: {
      __typename?: 'Account'
      id: number
      isVerified: boolean
      ecmNumber: string
      accountType: AccountType
      addresses: Array<{ __typename?: 'Address'; line1: string; city: string; state: string; postalCode: string }>
    }
  }
}

export type InboxFragment = {
  __typename?: 'Inbox'
  id: number
  account: {
    __typename?: 'Account'
    id: number
    isVerified: boolean
    ecmNumber: string
    accountType: AccountType
    addresses: Array<{ __typename?: 'Address'; line1: string; city: string; state: string; postalCode: string }>
  }
}

export type VirtualAddressFragment = {
  __typename?: 'Address'
  line1: string
  city: string
  state: string
  postalCode: string
}

export type GetRecipientsQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
}>

export type GetRecipientsQuery = {
  __typename?: 'Query'
  recipients: Array<{ __typename?: 'Recipient'; id: string; name: string }>
}

export type MailItemCheckDepositDataQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}>

export type MailItemCheckDepositDataQuery = {
  __typename?: 'Query'
  mailItemById: {
    __typename?: 'MailItem'
    deposit?: {
      __typename?: 'MailItemDeposit'
      shipmentDetails?: { __typename?: 'DepositShipmentDetails'; trackingNumber: string } | null
      bankAccountDetails: {
        __typename?: 'BankAccountDetails'
        name: string
        routingNumber: string
        accountNumberMasked: string
        accountType: BankAccountType
        bankName: string
        bankingInstitutionDeliveryType: BankingInstitutionDeliveryType
      }
    } | null
  }
}

export type GetMailItemHistoryQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}>

export type GetMailItemHistoryQuery = {
  __typename?: 'Query'
  mailItemHistory: Array<{ __typename?: 'MailItemHistoryEntry'; id: string; createdAt: any; description: string }>
}

export type GetStorageFeeDetailsForItemQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}>

export type GetStorageFeeDetailsForItemQuery = {
  __typename?: 'Query'
  mailItemById: {
    __typename?: 'MailItem'
    storage?: {
      __typename?: 'MailItemStorageDetails'
      gracePeriodEndsInDays: number
      outstandingStorageFeeInCents?: number | null
    } | null
  }
}

export type MailItemByIdQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemId: Scalars['ID']['input']
}>

export type MailItemByIdQuery = {
  __typename?: 'Query'
  mailItemById: {
    __typename?: 'MailItem'
    id: string
    barcode: string
    isViewed: boolean
    sender?: string | null
    receivedAt: any
    physicalStatus: MailItemPhysicalStatus
    digitalStatus: MailItemDigitalStatus
    checkStatus: MailItemCheckStatus
    senderCarrier?: string | null
    folder: MailItemFolder
    mailType: string
    mailSubtype?: string | null
    facility: string
    inShippingCart: boolean
    flags?: Array<MailItemFlag> | null
    recipient?: { __typename?: 'Recipient'; name: string } | null
    enclosure: { __typename?: 'MailItemEnclosure'; smallUrl?: string | null; largeUrl?: string | null }
    scan?: { __typename?: 'MailItemScan'; pdfUrl: string } | null
    physicalDimensions: {
      __typename?: 'MailItemPhysicalDimensions'
      weightInOunces: number
      pageCountEstimate: number
      pageCountActual?: number | null
    }
    itemActions: Array<
      | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
      | {
          __typename?: 'MailItemUnavailableAction'
          type: MailItemActionType
          unavailabilityReason: MailItemActionUnavailabilityReason
        }
    >
  }
}

export type SetNewMailroomEnabledMutationVariables = Exact<{
  input: SetNewMailroomEnabledInput
}>

export type SetNewMailroomEnabledMutation = {
  __typename?: 'Mutation'
  setNewMailroomEnabled: { __typename?: 'AccountFlag'; accountId: string; key: AccountFlagType; value: boolean }
}

export type ActionUnavailabilityReasonDescriptionFragment = {
  __typename?: 'ItemActionUnavailabilityReasonDescription'
  key: MailItemActionUnavailabilityReason
  description: string
}

export type GetActionUnavailabilityReasonMessagesQueryVariables = Exact<{ [key: string]: never }>

export type GetActionUnavailabilityReasonMessagesQuery = {
  __typename?: 'Query'
  itemActionUnavailabilityReasonDescriptions: Array<{
    __typename?: 'ItemActionUnavailabilityReasonDescription'
    key: MailItemActionUnavailabilityReason
    description: string
  }>
}

export type ItemActionsFragment = {
  __typename?: 'MailItem'
  itemActions: Array<
    | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
    | {
        __typename?: 'MailItemUnavailableAction'
        type: MailItemActionType
        unavailabilityReason: MailItemActionUnavailabilityReason
      }
  >
}

export type ErrorResultFragment = { __typename?: 'ErrorResult'; mailItemId: string }

export type GetMailItemsByIdsQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
  mailItemIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type GetMailItemsByIdsQuery = {
  __typename?: 'Query'
  mailItemsByIds: Array<{
    __typename?: 'MailItem'
    id: string
    receivedAt: any
    sender?: string | null
    facility: string
    enclosure: { __typename?: 'MailItemEnclosure'; largeUrl?: string | null }
    recipient?: { __typename?: 'Recipient'; name: string } | null
  }>
}

export type ShippingItemFragment = {
  __typename?: 'MailItem'
  id: string
  receivedAt: any
  sender?: string | null
  facility: string
  enclosure: { __typename?: 'MailItemEnclosure'; largeUrl?: string | null }
  recipient?: { __typename?: 'Recipient'; name: string } | null
}

export type AuthorizeShippingAddressMutationVariables = Exact<{
  input: AuthorizeShippingAddressInput
}>

export type AuthorizeShippingAddressMutation = {
  __typename?: 'Mutation'
  authorizeShippingAddress: {
    __typename?: 'ShippingAddress'
    id: string
    addressee: string
    company?: string | null
    line1: string
    line2?: string | null
    city?: string | null
    state?: string | null
    stateCode?: string | null
    country: string
    countryCode?: string | null
    postalCode?: string | null
    phone: string
    requiresItn: boolean
    requiresHsTariffCodes: boolean
  }
}

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>

export type GetCountriesQuery = {
  __typename?: 'Query'
  countriesConfiguration: Array<{ __typename?: 'CountryConfiguration'; name: string; abbreviation: string }>
}

export type CreateShippingAddressMutationVariables = Exact<{
  input: ShippingAddressInput
}>

export type CreateShippingAddressMutation = {
  __typename?: 'Mutation'
  createShippingAddress?: {
    __typename?: 'CreateShippingAddressResponse'
    shippingAddressId: string
    documentsToAuthorize: Array<{ __typename?: 'Document'; uri: string; name: string; format: string }>
  } | null
}

export type DocumentFragment = { __typename?: 'Document'; uri: string; name: string; format: string }

export type GetShippingAddressesQueryVariables = Exact<{
  inboxId: Scalars['ID']['input']
}>

export type GetShippingAddressesQuery = {
  __typename?: 'Query'
  getShippingAddresses: Array<{
    __typename?: 'ShippingAddress'
    id: string
    addressee: string
    company?: string | null
    line1: string
    line2?: string | null
    city?: string | null
    state?: string | null
    stateCode?: string | null
    country: string
    countryCode?: string | null
    postalCode?: string | null
    phone: string
    requiresItn: boolean
    requiresHsTariffCodes: boolean
  }>
  getMostRecentlyUsedShippingAddress?: {
    __typename?: 'ShippingAddress'
    id: string
    addressee: string
    company?: string | null
    line1: string
    line2?: string | null
    city?: string | null
    state?: string | null
    stateCode?: string | null
    country: string
    countryCode?: string | null
    postalCode?: string | null
    phone: string
    requiresItn: boolean
    requiresHsTariffCodes: boolean
  } | null
}

export type AddressFragment = {
  __typename?: 'ShippingAddress'
  id: string
  addressee: string
  company?: string | null
  line1: string
  line2?: string | null
  city?: string | null
  state?: string | null
  stateCode?: string | null
  country: string
  countryCode?: string | null
  postalCode?: string | null
  phone: string
  requiresItn: boolean
  requiresHsTariffCodes: boolean
}

export type ApplicantsByInboxIdQueryVariables = Exact<{
  input: ApplicantsByInboxIdInput
}>

export type ApplicantsByInboxIdQuery = {
  __typename?: 'Query'
  applicantsByInboxId: Array<{
    __typename?: 'Applicant'
    id: string
    emailAddress: string
    firstName?: string | null
    lastName?: string | null
    middleInitial?: string | null
  }>
}

export type ApplicantFragment = {
  __typename?: 'Applicant'
  id: string
  emailAddress: string
  firstName?: string | null
  lastName?: string | null
  middleInitial?: string | null
}

export type GetCartItemsQueryVariables = Exact<{
  input: GetCurrentShippingCartInput
}>

export type GetCartItemsQuery = {
  __typename?: 'Query'
  getCurrentShippingCart: {
    __typename?: 'ShippingCart'
    inboxId: string
    userId: string
    itemCount: number
    items: Array<{
      __typename?: 'MailItem'
      id: string
      receivedAt: any
      sender?: string | null
      facility: string
      enclosure: { __typename?: 'MailItemEnclosure'; largeUrl?: string | null }
      recipient?: { __typename?: 'Recipient'; name: string } | null
    }>
  }
}

export type RemoveItemsFromCartMutationVariables = Exact<{
  input: RemoveItemsFromShippingCartInput
}>

export type RemoveItemsFromCartMutation = {
  __typename?: 'Mutation'
  removeItemsFromShippingCart: {
    __typename?: 'ShippingCart'
    inboxId: string
    userId: string
    itemCount: number
    items: Array<{ __typename?: 'MailItem'; id: string }>
  }
}

export type RequestRatesMutationVariables = Exact<{
  input: RequestRatesInput
}>

export type RequestRatesMutation = {
  __typename?: 'Mutation'
  requestRates: {
    __typename?: 'RequestRatesResponse'
    insuranceValueInCents: number
    insurancePriceInCents: number
    shippingRates: Array<{
      __typename?: 'ShippingRate'
      id: string
      carrierType: string
      carrierLogoUrl: string
      priceInCents: number
      carrierService: string
      isTrackingAvailable: boolean
    }>
  }
}

export type CarrierRatesFragment = {
  __typename?: 'RequestRatesResponse'
  insuranceValueInCents: number
  insurancePriceInCents: number
  shippingRates: Array<{
    __typename?: 'ShippingRate'
    id: string
    carrierType: string
    carrierLogoUrl: string
    priceInCents: number
    carrierService: string
    isTrackingAvailable: boolean
  }>
}

export type CarrierRateFragment = {
  __typename?: 'ShippingRate'
  id: string
  carrierType: string
  carrierLogoUrl: string
  priceInCents: number
  carrierService: string
  isTrackingAvailable: boolean
}

export type RequestShipmentMutationVariables = Exact<{
  input?: InputMaybe<RequestShipmentInput>
}>

export type RequestShipmentMutation = {
  __typename?: 'Mutation'
  requestShipment: {
    __typename?: 'RequestShipmentResponse'
    shippingCart: {
      __typename?: 'ShippingCart'
      inboxId: string
      userId: string
      itemCount: number
      items: Array<{ __typename?: 'MailItem'; id: string }>
    }
    shipments: Array<{
      __typename?: 'Shipment'
      items: Array<{
        __typename?: 'MailItem'
        id: string
        physicalStatus: MailItemPhysicalStatus
        flags?: Array<MailItemFlag> | null
        inShippingCart: boolean
        itemActions: Array<
          | { __typename?: 'MailItemAvailableAction'; type: MailItemActionType }
          | {
              __typename?: 'MailItemUnavailableAction'
              type: MailItemActionType
              unavailabilityReason: MailItemActionUnavailabilityReason
            }
        >
      }>
    }>
  }
}

export type GetAccountFlagsQueryVariables = Exact<{
  inboxId: Scalars['Int']['input']
}>

export type GetAccountFlagsQuery = {
  __typename?: 'Query'
  getInbox: {
    __typename?: 'Inbox'
    id: number
    account: {
      __typename?: 'Account'
      id: number
      flags: Array<{ __typename?: 'AccountFlag'; accountId: string; key: AccountFlagType; value: boolean }>
    }
  }
}

export type GetInboxesQueryVariables = Exact<{ [key: string]: never }>

export type GetInboxesQuery = {
  __typename?: 'Query'
  getInboxes: Array<{
    __typename?: 'Inbox'
    id: number
    account: { __typename?: 'Account'; id: number; ecmNumber: string; name: string }
    pieceCounts: { __typename?: 'PieceCounts'; unreadPieceCount?: number | null }
  }>
}

export type GetShippingCartItemsCountQueryVariables = Exact<{
  input: GetCurrentShippingCartInput
}>

export type GetShippingCartItemsCountQuery = {
  __typename?: 'Query'
  getCurrentShippingCart: { __typename?: 'ShippingCart'; inboxId: string; userId: string; itemCount: number }
}

export const BankAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpedited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumberMasked' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BankAccountFragment, unknown>
export const BankAccountEdgeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountEdge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccountEdge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccount' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpedited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumberMasked' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BankAccountEdgeFragment, unknown>
export const CheckDepositBillingAddonOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckDepositBillingAddonOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositAddonOption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerCheckInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'freeChecksPerMonth' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckDepositBillingAddonOptionFragment, unknown>
export const CheckDepositBillingAddonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckDepositBillingAddon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBillingAddon' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkDepositAddonOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckDepositBillingAddonOption' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCheckDepositAddon' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckDepositBillingAddonOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositAddonOption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerCheckInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'freeChecksPerMonth' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckDepositBillingAddonFragment, unknown>
export const MailItemShareDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItemShareData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemShareData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generalLinkData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailShares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemShareDataFragment, unknown>
export const IntegrationProviderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IntegrationProvider' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationProvider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorizationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportedJobType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IntegrationProviderFragment, unknown>
export const ItemActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ItemActionsFragment, unknown>
export const MailItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'digitalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'smallUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pdfUrl' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'senderCarrier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'physicalDimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weightInOunces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountEstimate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountActual' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailSubtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inShippingCart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flags' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemFragment, unknown>
export const VirtualAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VirtualAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Address' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VirtualAddressFragment, unknown>
export const InboxFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inbox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Inbox' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ecmNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VirtualAddress' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VirtualAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Address' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<InboxFragment, unknown>
export const ActionUnavailabilityReasonDescriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionUnavailabilityReasonDescription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemActionUnavailabilityReasonDescription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ActionUnavailabilityReasonDescriptionFragment, unknown>
export const ErrorResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<ErrorResultFragment, unknown>
export const ShippingItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShippingItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ShippingItemFragment, unknown>
export const DocumentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DocumentFragment, unknown>
export const AddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresItn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresHsTariffCodes' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddressFragment, unknown>
export const ApplicantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Applicant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Applicant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleInitial' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ApplicantFragment, unknown>
export const CarrierRateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CarrierRate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierLogoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierService' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrackingAvailable' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CarrierRateFragment, unknown>
export const CarrierRatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CarrierRates' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestRatesResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CarrierRate' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceValueInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurancePriceInCents' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CarrierRate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierLogoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierService' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrackingAvailable' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CarrierRatesFragment, unknown>
export const MailItemDepositDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mailItemDepositData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deposit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'depositRequestId' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemDepositDataQuery, MailItemDepositDataQueryVariables>
export const CancelMailItemsCheckDepositDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelMailItemsCheckDeposit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelMailItemsCheckDepositInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMailItemsCheckDeposit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CancelMailItemsCheckDepositMutation, CancelMailItemsCheckDepositMutationVariables>
export const CancelMailItemsScanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelMailItemsScan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelMailItemsScanInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMailItemsScan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'digitalStatus' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CancelMailItemsScanMutation, CancelMailItemsScanMutationVariables>
export const GetShipmentByItemIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getShipmentByItemId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetShipmentByItemIdInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getShipmentByItemId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetShipmentByItemIdQuery, GetShipmentByItemIdQueryVariables>
export const CancelShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelShipmentInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CancelShipmentMutation, CancelShipmentMutationVariables>
export const CancelItemsShredDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelItemsShred' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelMailItemsShredInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMailItemsShred' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CancelItemsShredMutation, CancelItemsShredMutationVariables>
export const UpdateMailItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMailItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMailItemInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMailItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sender' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateMailItemMutation, UpdateMailItemMutationVariables>
export const RequestMailItemsCheckDepositDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestMailItemsCheckDeposit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestMailItemsCheckDepositInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestMailItemsCheckDeposit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RequestMailItemsCheckDepositMutation, RequestMailItemsCheckDepositMutationVariables>
export const CheckDepositDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkDepositData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkDepositBankAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pageSize' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccountEdge' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCheckDepositBillingAddon' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckDepositBillingAddon' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpedited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumberMasked' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckDepositBillingAddonOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositAddonOption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricePerCheckInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'freeChecksPerMonth' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccountEdge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccountEdge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'node' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccount' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckDepositBillingAddon' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBillingAddon' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkDepositAddonOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CheckDepositBillingAddonOption' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCheckDepositAddon' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckDepositDataQuery, CheckDepositDataQueryVariables>
export const ValidateBankRoutingNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'validateBankRoutingNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ValidateBankRoutingNumberInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateBankRoutingNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ValidateBankRoutingNumberMutation, ValidateBankRoutingNumberMutationVariables>
export const CreateCheckDepositBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCheckDepositBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCheckDepositBankAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCheckDepositBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BankAccount' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BankAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckDepositBankAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isExpedited' } },
          { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumberMasked' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateCheckDepositBankAccountMutation, CreateCheckDepositBankAccountMutationVariables>
export const MarkMailItemsNotViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markMailItemsNotViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkMailItemsNotViewedInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markMailItemsNotViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<MarkMailItemsNotViewedMutation, MarkMailItemsNotViewedMutationVariables>
export const MarkMailItemsViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markMailItemsViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkMailItemsViewedInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markMailItemsViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<MarkMailItemsViewedMutation, MarkMailItemsViewedMutationVariables>
export const MoveMailItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'moveMailItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MoveMailItemsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveMailItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<MoveMailItemsMutation, MoveMailItemsMutationVariables>
export const MarkNotMyMailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markNotMyMails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarkNotMyMailsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotMyMails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<MarkNotMyMailsMutation, MarkNotMyMailsMutationVariables>
export const RequestItemsScanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestItemsScan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestMailItemsScanInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestMailItemsScan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'digitalStatus' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RequestItemsScanMutation, RequestItemsScanMutationVariables>
export const SetMailItemCheckDetectedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setMailItemCheckDetected' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetMailItemCheckDetectedInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMailItemCheckDetected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flags' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SetMailItemCheckDetectedMutation, SetMailItemCheckDetectedMutationVariables>
export const CreateGeneralShareLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGeneralShareLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGeneralShareLinkInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGeneralShareLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generalLinkData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateGeneralShareLinkMutation, CreateGeneralShareLinkMutationVariables>
export const CreateMailItemIntegrationJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMailItemIntegrationJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMailItemIntegrationJobsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMailItemIntegrationJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateMailItemIntegrationJob' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateMailItemIntegrationJobsMutation, CreateMailItemIntegrationJobsMutationVariables>
export const MailItemShareEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mailItemShareEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemShareEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemShareEmailsQuery, MailItemShareEmailsQueryVariables>
export const ShareMailItemsByEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'shareMailItemsByEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareMailItemsByEmailInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareMailItemsByEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemShareData' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailShares' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<ShareMailItemsByEmailMutation, ShareMailItemsByEmailMutationVariables>
export const DeleteShareLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteShareLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteShareLinkInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteShareLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailShares' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteShareLinkMutation, DeleteShareLinkMutationVariables>
export const MailItemsShareDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mailItemsShareData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemsShareData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemIds' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailItemShareData' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItemShareData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemShareData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generalLinkData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailShares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemsShareDataQuery, MailItemsShareDataQueryVariables>
export const ToggleShareLinkAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'toggleShareLinkAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ToggleShareLinkAccessInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleShareLinkAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailItemShareData' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItemShareData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemShareData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generalLinkData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailShares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'linkKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ToggleShareLinkAccessMutation, ToggleShareLinkAccessMutationVariables>
export const AddItemsToShippingCartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addItemsToShippingCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddItemsToShippingCartInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addItemsToShippingCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inboxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inShippingCart' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddItemsToShippingCartMutation, AddItemsToShippingCartMutationVariables>
export const ShredMailItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'shredMailItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestMailItemsShredInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestMailItemsShred' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flags' } }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErrorResult' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErrorResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mailItemId' } }]
      }
    }
  ]
} as unknown as DocumentNode<ShredMailItemsMutation, ShredMailItemsMutationVariables>
export const IntegrationProvidersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'integrationProviders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integrationProviders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'IntegrationProvider' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IntegrationProvider' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationProvider' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'providerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authorizationUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportedJobType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IntegrationProvidersQuery, IntegrationProvidersQueryVariables>
export const GetMailItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMailItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemFilters' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailItem' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'digitalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'smallUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pdfUrl' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'senderCarrier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'physicalDimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weightInOunces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountEstimate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountActual' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailSubtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inShippingCart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flags' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMailItemsQuery, GetMailItemsQueryVariables>
export const GetInboxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInbox' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInbox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Inbox' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VirtualAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Address' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inbox' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Inbox' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ecmNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VirtualAddress' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetInboxQuery, GetInboxQueryVariables>
export const GetRecipientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRecipients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetRecipientsQuery, GetRecipientsQueryVariables>
export const MailItemCheckDepositDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mailItemCheckDepositData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deposit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } }]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankAccountDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountNumberMasked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankingInstitutionDeliveryType' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemCheckDepositDataQuery, MailItemCheckDepositDataQueryVariables>
export const GetMailItemHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMailItemHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMailItemHistoryQuery, GetMailItemHistoryQueryVariables>
export const GetStorageFeeDetailsForItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStorageFeeDetailsForItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gracePeriodEndsInDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outstandingStorageFeeInCents' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetStorageFeeDetailsForItemQuery, GetStorageFeeDetailsForItemQueryVariables>
export const MailItemByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'mailItemById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MailItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MailItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'digitalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'smallUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pdfUrl' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'senderCarrier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'folder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'physicalDimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weightInOunces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountEstimate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pageCountActual' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mailType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailSubtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inShippingCart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flags' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MailItemByIdQuery, MailItemByIdQueryVariables>
export const SetNewMailroomEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setNewMailroomEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetNewMailroomEnabledInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setNewMailroomEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SetNewMailroomEnabledMutation, SetNewMailroomEnabledMutationVariables>
export const GetActionUnavailabilityReasonMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActionUnavailabilityReasonMessages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActionUnavailabilityReasonDescriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionUnavailabilityReasonDescription' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionUnavailabilityReasonDescription' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemActionUnavailabilityReasonDescription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetActionUnavailabilityReasonMessagesQuery,
  GetActionUnavailabilityReasonMessagesQueryVariables
>
export const GetMailItemsByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMailItemsByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailItemsByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mailItemIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mailItemIds' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShippingItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShippingItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMailItemsByIdsQuery, GetMailItemsByIdsQueryVariables>
export const AuthorizeShippingAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'authorizeShippingAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthorizeShippingAddressInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorizeShippingAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresItn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresHsTariffCodes' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AuthorizeShippingAddressMutation, AuthorizeShippingAddressMutationVariables>
export const GetCountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countriesConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abbreviation' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>
export const CreateShippingAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createShippingAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddressInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShippingAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shippingAddressId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentsToAuthorize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateShippingAddressMutation, CreateShippingAddressMutationVariables>
export const GetShippingAddressesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getShippingAddresses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getShippingAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMostRecentlyUsedShippingAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresItn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresHsTariffCodes' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetShippingAddressesQuery, GetShippingAddressesQueryVariables>
export const ApplicantsByInboxIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'applicantsByInboxId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicantsByInboxIdInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicantsByInboxId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Applicant' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Applicant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Applicant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'middleInitial' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ApplicantsByInboxIdQuery, ApplicantsByInboxIdQueryVariables>
export const GetCartItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCartItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCurrentShippingCartInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCurrentShippingCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inboxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShippingItem' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShippingItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facility' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enclosure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'largeUrl' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCartItemsQuery, GetCartItemsQueryVariables>
export const RemoveItemsFromCartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeItemsFromCart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RemoveItemsFromShippingCartInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeItemsFromShippingCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inboxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RemoveItemsFromCartMutation, RemoveItemsFromCartMutationVariables>
export const RequestRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestRatesInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CarrierRates' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CarrierRate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierLogoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrierService' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrackingAvailable' } }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CarrierRates' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestRatesResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CarrierRate' } }]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'insuranceValueInCents' } },
          { kind: 'Field', name: { kind: 'Name', value: 'insurancePriceInCents' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RequestRatesMutation, RequestRatesMutationVariables>
export const RequestShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestShipmentInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippingCart' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'inboxId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'physicalStatus' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemActions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'flags' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inShippingCart' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemActions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemAvailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailItemUnavailableAction' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unavailabilityReason' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RequestShipmentMutation, RequestShipmentMutationVariables>
export const GetAccountFlagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountFlags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInbox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inboxId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inboxId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAccountFlagsQuery, GetAccountFlagsQueryVariables>
export const GetInboxesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInboxes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInboxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ecmNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pieceCounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'unreadPieceCount' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetInboxesQuery, GetInboxesQueryVariables>
export const GetShippingCartItemsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getShippingCartItemsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCurrentShippingCartInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCurrentShippingCart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inboxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetShippingCartItemsCountQuery, GetShippingCartItemsCountQueryVariables>
