import { ThemeOptions } from '@mui/material/styles'

export const MuiDrawer: Exclude<ThemeOptions['components'], undefined>['MuiDrawer'] = {
  styleOverrides: {
    paper: ({ theme: { spacing } }) => ({
      minWidth: 'min(400px, 100%)',
      maxWidth: 'min(400px, 100%)',
      padding: spacing(3)
    })
  }
}
