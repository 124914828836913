import { ThemeOptions } from '@mui/material/styles'

export const MuiMenu: Exclude<ThemeOptions['components'], undefined>['MuiMenu'] = {
  styleOverrides: {
    paper: ({ theme: { spacing, palette } }) => ({
      marginTop: spacing(1),
      boxShadow: `0px 4px 16px 0px ${palette.grey[800]}1F`
    }),
    list: ({ theme: { spacing } }) => ({
      padding: spacing(2, 1),
      gap: spacing(2),
      minWidth: 236
    })
  }
}

export const MuiMenuItem: Exclude<ThemeOptions['components'], undefined>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme: { shape, spacing } }) => ({
      borderRadius: shape.borderRadius,
      padding: spacing(2, 3)
    })
  }
}
