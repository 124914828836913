import { ThemeOptions } from '@mui/material/styles'
import { alpha } from '@mui/material'

export const MuiDialog: Exclude<ThemeOptions['components'], undefined>['MuiDialog'] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing } }) => ({
      '& .MuiBackdrop-root': {
        backgroundColor: alpha(palette.grey[800], 0.5)
      },
      padding: spacing(4)
    }),
    paper: ({ theme: { palette, spacing } }) => ({
      boxShadow: `0px 4px 12px 0px ${palette.grey[800]}14`,
      gap: spacing(6),
      padding: spacing(6),
      margin: 0,
      maxHeight: '100%',
      minWidth: 'min(480px, 100%)',
      maxWidth: 'min(480px, 100%)'
    })
  }
}

export const MuiDialogTitle: Exclude<ThemeOptions['components'], undefined>['MuiDialogTitle'] = {
  defaultProps: {
    variant: 'subtitle2',
    fontWeight: 600
  },
  styleOverrides: {
    root: {
      display: 'flex',
      gap: 6,
      alignItems: 'flex-start',
      padding: 0,
      '& > *:last-child': {
        marginLeft: 'auto'
      }
    }
  }
}

export const MuiDialogContent: Exclude<ThemeOptions['components'], undefined>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      '&:has(.MuiDialogContentText-root:first-child)': {
        marginTop: theme.spacing(-4)
      }
    })
  }
}

export const MuiDialogContentText: Exclude<ThemeOptions['components'], undefined>['MuiDialogContentText'] = {
  defaultProps: {
    color: 'textSecondary'
  }
}

export const MuiDialogActions: Exclude<ThemeOptions['components'], undefined>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      gap: theme.spacing(2),
      display: 'flex',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      '& .MuiButton-root': {
        margin: 0,
        flex: 1,
        minWidth: 'fit-content'
      }
    })
  }
}
