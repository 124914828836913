import { EnvConfig } from './envconfig'

export const config: EnvConfig = {
  myLzUrl: 'https://wwwqa.legalzoom.com/my',
  appUrl: 'https://app.qa.ecm.network',
  apiBaseURL: 'https://api.qa.ecm.network',
  graphqlBaseURL: 'https://graphql-api.qa.ecm.network/graphql',
  googleTagManagerId: 'GTM-KCT9F9D',
  launchDarklyClientSideId: '65b8c714aef4ed0fbd907512',
  amplitudeApiKey: '162f8f6b9185a6eb582d2b34ed6e9dfb',
  hotjarId: '',
  hotjarSnippetVersion: '6',
  appCookieDomain: 'ecm.network',
  awsSecureCookie: true,
  awsProjectRegion: 'us-west-2',
  awsCognitoRegion: 'us-west-2',
  awsUserPoolsId: 'us-west-2_SFkFjbQt9',
  awsUserPoolsWebClientId: '5c9nalj4t7qu8h1sp7rn5g1g8c',
  awsUserPoolsMarketingClientId: '9fjgtj5fa712lq22s22jck71p',
  awsOAuthDomain: 'auth-qa.earthclassmail.com',
  awsOAuthSignInRedirect: 'https://app.qa.ecm.network/login',
  awsOAuthSignOutRedirect: 'https://app.qa.ecm.network/logout',
  marketingRedirectUrl: 'https://www.qa.ecm.network/checkout/billing',
  pusherAppKey: '',
  pusherCluster: 'mt1',
  googleMapsApiKey: 'AIzaSyCA3rYW9A91HDYOp9kJzj0QtiNTvGNDfNo',
  stripePublicKey: 'pk_test_Q1j4pTOxcBaHe9dGs4ANbFK4',
  connectorServiceBaseUrl: 'https://connector.qa.ecm.network',
  connectorServiceBillcomEndpoint: 'billcom_sandbox',
  sharePortalUrl: 'https://share-portal.qa.ecm.network/',
  datadogAppId: '3ce82eed-4ac8-4a20-9f45-c5cb7e8c7629',
  datadogClientToken: 'pub9fb5929d4d37cfb6bcbc5b9e0821eb7e',
  env: 'qa'
}
