import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import fonts from 'material-ui/Fonts'
import { palette } from 'theme/palette'
import { typography } from 'theme/typography'
import { MuiAutocomplete } from 'theme/components/MuiAutocomplete'
import { MuiChip } from 'theme/components/MuiChip'
import { MuiButton } from 'theme/components/MuiButton'
import { MuiBadge } from 'theme/components/MuiBadge'
import { MuiAvatar } from 'theme/components/MuiAvatar'
import { MuiTooltip } from 'theme/components/MuiTooltip'
import { MuiAccordion, MuiAccordionSummary } from 'theme/components/MuiAccordion'
import { MuiTab, MuiTabs } from 'theme/components/MuiTabs'
import { MuiList, MuiListItemButton, MuiListSubheader } from 'theme/components/MuiList'
import { MuiAlert } from 'theme/components/MuiAlert'
import { MuiCheckbox } from 'theme/components/MuiCheckbox'
import { MuiSwitch } from 'theme/components/MuiSwitch'
import { MuiSelect } from 'theme/components/MuiSelect'
import { MuiMenu, MuiMenuItem } from 'theme/components/MuiMenu'
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiDialogContentText
} from 'theme/components/MuiDialog'
import { MuiDrawer } from 'theme/components/MuiDrawer'
import { MuiInputLabel, MuiOutlinedInput } from 'theme/components/MuiTextField'
import { MuiFormControlLabel } from 'theme/components/MuiFormControlLabel'
import { MuiRadio } from 'theme/components/MuiRadio'
import { MuiLink } from 'theme/components/MuiLink'
import { MuiFormHelperText } from 'theme/components/MuiFormHelperText'
import { MuiDivider } from 'theme/components/MuiDivider'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

export const lzTheme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    shape: {
      borderRadius: 8
    },
    typography,
    palette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `${fonts}`
      },
      MuiAccordion,
      MuiAccordionSummary,
      MuiAutocomplete,
      MuiChip,
      MuiButton,
      MuiBadge,
      MuiAvatar,
      MuiTooltip,
      MuiTabs,
      MuiTab,
      MuiList,
      MuiListItemButton,
      MuiListSubheader,
      MuiAlert,
      MuiCheckbox,
      MuiSwitch,
      MuiSelect,
      MuiMenu,
      MuiMenuItem,
      MuiDialog,
      MuiDialogTitle,
      MuiDialogContent,
      MuiDialogContentText,
      MuiDialogActions,
      MuiDrawer,
      MuiInputLabel,
      MuiOutlinedInput,
      MuiFormHelperText,
      MuiFormControlLabel,
      MuiRadio,
      MuiLink,
      MuiDivider
    }
  })
)
